<template>
  <div>
    <el-container>
      <el-header></el-header>
      <el-dialog title="Remove Success" :visible.sync="showdialog">
        <div class="dialog-header">
          <div class="download-button-container">
            <el-tooltip
              class="item"
              effect="dark"
              content="Download"
              placement="top"
            >
              <el-button
                class="download-button"
                type="primary"
                icon="el-icon-download"
                @click="handleDownload"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="image-container">
          <img
            v-if="processedImage"
            :src="processedImage"
            alt="Processed Image"
            style="max-width: 100%; max-height: 100%"
          />
        </div>
      </el-dialog>
      <el-main
        class="custom-main"
        v-loading="loading"
        element-loading-text="Background removal in progress. Please wait."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="upload-container">
          <img
            class="uploaded-image"
            :src="require('@/assets/upload.png')"
            alt="Uploaded Image"
          />
          <el-upload
            class="upload-demo"
            action="*"
            :multiple="false"
            :http-request="UploadImage"
            ref="upload"
            :before-upload="beforeAvatarUpload"
          >
            <el-button class="upload-button" type="primary" size="medium"
              >Upload Image</el-button
            >
          </el-upload>

          <div class="description">
            The image file size cannot exceed 2MB (supports image formats .png
            and .jpg)
          </div>
        </div>
      </el-main>
      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import imglyRemoveBackground from "@imgly/background-removal";

export default {
  name: "App",
  components: {},
  data() {
    return {
      loading: false,
      showdialog: false,
      processedImage: null, // 存储处理后的图片数据
    };
  },
  methods: {
    UploadImage(request) {
      var uploadedFile = request.file;
      this.$refs.upload.clearFiles(); // 清除已选择的文件
      console.log("文件:", uploadedFile);
      // 执行你的自定义逻辑，处理 uploadedFile，如获取文件名、大小等
      console.log("文件名:", uploadedFile.name);
      console.log("文件大小:", uploadedFile.size);
      console.log("开始去除背景");
      this.loading = true;
      // imglyRemoveBackground(uploadedFile)
      //   .then((blob) => {
      //     this.loading = false;
      //     console.log("去除背景成功");
      //     this.showdialog = true;
      //     const imageUrl = URL.createObjectURL(blob);
      //     this.processedImage = imageUrl;
      //     console.log("设置背景图片成功");
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     console.error("发生异常:", error);
      //     this.$message.error("Network exception, please try again!");
      //     // 在这里处理异常，可以显示错误信息或执行其他操作
      //   });
      imglyRemoveBackground(uploadedFile)
        .then((blob) => {
          this.loading = false;
          console.log("去除背景成功");

          // 创建FileReader对象
          var reader = new FileReader();

          // 读取Blob数据并转换为Base64字符串
          reader.onloadend = () => {
            var base64Data = reader.result;
            this.showdialog = true;
            this.processedImage = base64Data;
            console.log("设置背景图片成功");
          };

          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          this.loading = false;
          console.error("发生异常:", error);
          this.$message.error("Network exception, please try again!");
          // 在这里处理异常，可以显示错误信息或执行其他操作
        });
    },
    handleDownload() {
      // 处理下载逻辑
      // window.chrome.webview.postMessage({
      //   type: "saveFile",
      //   imageData: this.processedImage,
      // });
      if (!this.processedImage) {
        this.$message.error("No processed image to download.");
        return;
      }

      // Convert the base64 image data to a Blob
      const byteCharacters = atob(this.processedImage.split(",")[1]);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "image/png" }); // You can change the type accordingly if needed

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "processed_image.png"; // Set the filename for download

      // Simulate a click to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("Upload images can only be in JPG or PNG format!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("The uploaded image size cannot exceed 2MB!");
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.custom-main {
  width: calc(100vw * 0.618);
  height: calc(100vh * 0.618);
  text-align: center;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;
}

.uploaded-image {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 20px;
}
.el-loading-text {
  font-size: 50px; /* Add your desired font size here */
}
.upload-container .upload-button {
  width: 200px;
  height: 50px;
  font-size: 22px;
  margin-bottom: 20px !important;
}

.image-container {
  display: flex;
  justify-content: center;
}

.dialog-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
